@import "@/styles/wx_breakpoints.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.wx-tile-panel {
  .kpi {
    background-color: var(--color-element-layer2);
    border-radius: var(--border-radius-sm);
  }
}

.kpi {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  border: 1px solid var(--color-border-theme);
  border-radius: var(--border-radius-lg);
}

.kpi-title-and-target {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  flex-shrink: 1;
}

.kpi-value {
  margin-left: auto;
  flex-shrink: 0;
}

.kpi-indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 3px solid transparent;
  flex-shrink: 0;

  &:before {
    content: "";
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    border-radius: 50%;
  }

  &.good {
    border-color: var(--color-success);
    &:before {
      background: var(--color-success-alpha);
    }
  }

  &.average {
    border-color: var(--color-warning);
    &:before {
      background: var(--color-warning-alpha);
    }
  }

  &.bad {
    border-color: var(--color-error);
    &:before {
      background: var(--color-error-alpha);
    }
  }

  &.unknown {
    border-color: var(--color-secondary);
    &:before {
      background: var(--color-secondary-alpha);
    }
  }

  &.no-metric {
    border-color: var(--color-neutral);
    &:before {
      background: var(--color-neutral-alpha);
    }
  }
}
