@import "@/styles/wx_breakpoints.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.loading {
  height: 100%;
}
.dropdown-nav,
.filter-style {
  min-width: 14rem;
}
.dropdown-nav {
  flex-grow: 0;
  max-width: 20rem;

  @media ($wx-xl-min) {
    max-width: 22rem;
  }

  .time-selector-option {
    font-weight: 700;
  }
  .time-selector-date {
    color: var(--color-text-subtle-theme);
  }
}
.filter-style {
  max-width: 20rem;

  @media ($wx-sm-min) {
    max-width: 15rem;
  }
}
