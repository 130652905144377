@import "@/styles/wx_breakpoints.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

// local var
$tilesBorderColor: transparent;
$tilesBorderValue: 0;
$tilesBorderWidth: #{$tilesBorderValue}px;
$doubleTilesBordersWidth: #{(2 * $tilesBorderValue)}px;

/**
  * PRODUCT UNITS CARDS
  * These cards are styles as Dashboard Tiles
  * with the exception of the thicker top-border
  * size defined by `.pu-card:after` border-width
  * and border-color by the by PU states (below).
  */
.pu-card {
  &.v-card {
    position: relative;
    margin-top: var(--border-radius-lg);
    padding: var(--box-padding-dashboard);
    border: $tilesBorderWidth solid $tilesBorderColor;
    border-top: none; // top-border = .pu-card:after
    border-radius: 0 0 var(--border-radius-lg) var(--border-radius-lg) !important;
    background-color: var(--color-base-background) !important;
    cursor: pointer;
    font-size: 1vh;

    &.v-card {
      box-shadow: none;
    }

    &:after {
      content: "";
      position: absolute;
      top: calc(var(--border-radius-lg) * -1); // turn it in negative value
      left: -($tilesBorderWidth);
      width: calc(100% + #{$doubleTilesBordersWidth});
      height: var(--border-radius-lg);
      border: $tilesBorderWidth solid transparent;
      border-bottom-width: 0;
      border-radius: var(--border-radius-lg) var(--border-radius-lg) 0 0;
    }

    .card-body {
      padding-bottom: 4px;

      &:not(.is-presenter) {
        padding-bottom: calc(var(--box-padding-dashboard) + 30px); // match v-btn height
      }

      &.is-fullscreen {
        height: calc(50vh - (200px + var(--border-radius-lg)));
      }
    }
    .card-footer {
      position: absolute;
      bottom: 0;
      width: calc(100% - var(--box-padding-dashboard) - var(--box-padding-dashboard));
      padding-bottom: var(--box-padding-dashboard);

      .btn-pu-details-control {
        .v-icon {
          transform: rotate(0deg);
        }
      }
    }

    /**
   * PU states
   */
    &.up:after {
      background-color: var(--color-uptime);
    }
    &.down_unjustified:after {
      background-color: var(--color-error);
    }
    &.down_unplanned:after {
      background-color: var(--color-justifiedDowntime);
    }
    &.down_planned:after {
      background-color: var(--color-plannedDowntime);
    }
    &.out_of_production:after {
      background-color: var(--color-outOfProduction);
      border-color: var(--color-border-theme); // untested on localhost
    }
    &.unknown:after {
      background-color: var(--color-unknown);
      border-color: var(--color-border-theme); // untested on localhost
    }
    &.carbon-frozen:after {
      // carbon frozen ? Yes, like Han Solo in The Empire Strikes Back :D
      background-color: inherit;
      border-color: var(--color-border-theme); // untested on localhost
    }

    // .pu-card.details-active
    &.details-active {
      background-color: var(--color-element-layer2) !important;
      box-shadow: var(--box-shadow-high-elevation);

      .card-footer {
        .btn-pu-details-control {
          background-color: var(--color-control-subtle-theme);

          .v-icon {
            transform: rotate(180deg);
          }
        }
      }
    }
    /* .pu-card.disconnected */
    &.disconnected {
      background-color: var(--color-disconnected) !important;

      &:after {
        border-bottom: 0;
        background-color: var(--color-disconnected);
      }

      .wx-typo-h1 {
        display: inline;
        white-space: normal;
      }

      // Based on Martine comments it should not be visible
      .btn-pu-details-control {
        display: none;
      }

      // Light mode does NOT get text-shadow
      @at-root {
        .theme--dark {
          .wx-typo-h2 {
            text-shadow: var(--text-shadow-small-level);
          }
          .pu-subheader {
            text-shadow: var(--text-shadow-smallest-level);
          }
          .wx-typo-h1 {
            text-shadow: var(--text-shadow-high-level);
          }
        }
      }
    }
  }
}

// This weird CSS is to get "text-overflow: ellipsis" to work with a fluid width
.ellipsis {
  position: relative;

  &:before {
    content: "&nbsp;";
    visibility: hidden;
  }

  span {
    position: absolute;
    left: 0;
    right: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.kpi-circle {
  position: relative;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  margin: 0 auto;
  border: 10px solid transparent;

  &.is-fullscreen {
    font-size: 3vh;
  }

  &:not(.is-fullscreen) {
    font-size: 2vh;
  }

  &:before {
    content: "";
    position: absolute;
    width: calc(100% - 10px);
    height: calc(100% - 10px);
    border-radius: 50%;
  }

  @media ($wx-sm-min) {
    &.is-fullscreen {
      width: calc(50vh - 325px);
      height: calc(50vh - 325px);
    }
    &:not(.is-fullscreen) {
      width: 120px;
      height: 120px;
      border: 12px solid transparent;
    }

    &:before {
      width: calc(100% - 12px);
      height: calc(100% - 12px);
    }
  }

  @media ($wx-md-min) {
    &.is-fullscreen {
      width: calc(50vh - 325px);
      height: calc(50vh - 325px);
    }
    &:not(.is-fullscreen) {
      width: 140px;
      height: 140px;
      border: 14px solid transparent;
    }

    &:before {
      width: calc(100% - 14px);
      height: calc(100% - 14px);
    }
  }

  @media ($wx-lg-min) {
    &.is-fullscreen {
      width: calc(50vh - 325px);
      height: calc(50vh - 325px);
    }
    &:not(.is-fullscreen) {
      width: 160px;
      height: 160px;
    }

    &:before {
      width: calc(100% - 16px);
      height: calc(100% - 16px);
    }
  }

  @media ($wx-xl-min) {
    &.is-fullscreen {
      width: calc(50vh - 350px);
      height: calc(50vh - 350px);
    }
    &:not(.is-fullscreen) {
      width: 180px;
      height: 180px;
    }

    &:before {
      width: calc(100% - 25px);
      height: calc(100% - 25px);
    }
  }

  &.good {
    border-color: var(--color-success);
    &:before {
      background: var(--color-success-alpha);
    }
  }

  &.average {
    border-color: var(--color-warning);
    &:before {
      background: var(--color-warning-alpha);
    }
  }

  &.bad {
    border-color: var(--color-error);
    &:before {
      background: var(--color-error-alpha);
    }
  }

  &.no-metric {
    border-color: var(--color-neutral);
    &:before {
      background: var(--color-neutral-alpha);
    }
  }
}

.kpi-value,
.kpi-label {
  position: relative;
  z-index: 1;
}

.kpi-name {
  position: relative;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
}

.invisible-chip {
  visibility: hidden;
}
