@import "@/styles/wx_breakpoints.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.canvas-container {
  position: relative;
  width: 100%;
  height: 200px;
}

::v-deep canvas {
  width: 320px;
  height: 200px;
  max-width: 400px;
  margin: 0 auto;
}

.wx-tile-panel {
  .pu-time-container {
    padding-bottom: var(--box-padding-dashboard);
  }

  @media ($wx-sm-min) {
    .pu-time-container {
      padding-bottom: 0;
    }
  }
}

/**
 * Under the graph
 *
 * the `.data small` markup does not exist yet.
 * it requires to parse html `<small>`
 * within the {{ totalDowntimeAsString }}
 * in
 */
.time-data-list {
  max-width: 18rem;
  margin-inline: auto;
  background-color: var(--color-element-layer2);

  .label,
  .data small {
    color: var(--color-text-subtle-theme);
  }
  .data small {
    font-size: var(--font-size-sm);
  }
}
