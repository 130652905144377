@import "@/styles/wx_breakpoints.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.pu-details {
  position: relative;
  display: flex;
  flex-flow: row wrap;
  grid-column: 1 / -1;
  padding: var(--box-padding-dashboard);
  border: 1px solid var(--color-border-theme);
  border-radius: var(--border-radius-lg);
  background-color: var(--color-element-layer2);
  box-shadow: var(--box-shadow-high-elevation);

  @media ($wx-sm-min) {
    border: none;
    background-color: var(--color-base-layer1);
    box-shadow: none;
  }
}

.title {
  width: 100%;
  padding-right: 40px;
  margin-bottom: var(--box-padding-dashboard);
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
}

.v-tab {
  min-width: 80px;
  padding: 0 12px;
}
.v-tabs-items {
  width: 100%;
}

::v-deep .wx-tile-panel {
  background-color: var(--color-element-layer2);

  @media ($wx-sm-min) {
    &.pu-details-kpis,
    &.pu-details-timeline {
      width: calc((100% - var(--box-padding-dashboard)) / 2);
    }

    &.pu-details-kpis {
      margin-right: var(--box-padding-dashboard);
    }

    &.pu-details-downtimes {
      width: 100%;
      margin-top: var(--box-padding-dashboard);
    }
  }

  @media ($wx-md-min) {
    &.pu-details-kpis,
    &.pu-details-downtimes,
    &.pu-details-timeline {
      width: calc((100% - var(--box-padding-dashboard) * 2) / 3);
    }

    &.pu-details-kpis,
    &.pu-details-downtimes {
      margin-right: var(--box-padding-dashboard);
    }

    &.pu-details-downtimes {
      margin-top: 0;
    }
  }
}
