@import "@/styles/wx_breakpoints.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.overviewTitle {
  padding-top: 20px;
}
.PU-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: var(--grid-gutter);
  grid-auto-flow: row dense;
  padding-left: 7px; // spacer for shadow from elevation props
  padding-bottom: var(--grid-gutter);

  &.clicked {
    border: 2px solid red;
  }
  @media ($wx-sm-min) {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: calc(var(--grid-gutter) / 2);
  }

  @media ($wx-md-min) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media ($wx-lg-min) {
    grid-template-columns: repeat(5, 1fr);
  }

  @media ($wx-xl-min) {
    grid-template-columns: repeat(6, 1fr);
  }

  /**
  * Dimming the visibility of the unactive
  * siblings of a `pu-cards.details-active`
  * from PUItems.vue
  */
  article {
    opacity: 1;
    transition: var(--smooth-transition);
  }

  &.child-clicked {
    article:not(.disconnected) {
      // all cards that are not clicked
      &:not(.details-active) {
        opacity: var(--opacity-disabled-effect);
      }
      // Un mouse hover users can better read the unactivated card
      &:hover {
        opacity: 1 !important;
      }
    }
  }
}
